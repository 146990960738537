let key = {};
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://panamaapi.wearedev.team";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://panama.wearedev.team",
    ADMIN_URL: "https://panama-exchange-v2-admin-panel.pages.dev",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    API_URL: `${API_URL}:2061`,
    getGeoInfo: "https://ipapi.co/json/",
  };
}

export default key;
