import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import nav from "src/_nav";
import { useSelector } from "react-redux";

// import lib
import { isLogin } from "../lib/localStorage";

let generalAccess = [
  "/",
  "/login",
  "/dashboard",
  "/profile",
  "/change-password",
  "/login-history",
];

const ConditionRoute = (props) => {
  const { type, children } = props;
  const currentLocation = useLocation();
  const { restriction, role } = useSelector((state) => state.role);
  const [access, setAccess] = useState(true);

  function checkAccess(restrict) {
    return nav.find((el) => el.items ? el.items.find((i) => i.to==currentLocation.pathname && i.name == restrict) : el.to==currentLocation.pathname && el.name == restrict);
  }

  useEffect(() => {
    if (restriction && restriction.length > 0) {
      if(generalAccess.includes(currentLocation.pathname))
        setAccess(true);
      else
        setAccess(restriction.some(checkAccess));
    }
  }, [restriction]);

  if (type === "auth" && isLogin() === true) {
    return <Navigate to="/dashboard" />;
  } else if (type === "private" && isLogin() !== true) {
    return <Navigate to="/login" />;
  } else if (role != "superadmin" && !access) {
    return <Navigate to="/404" />;
  }

  return children;
};

ConditionRoute.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(ConditionRoute);